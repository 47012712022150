import React, { createContext, useEffect } from 'react';
import axios from 'axios';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getSessionStorageWithExpiry } from './utils/utils';
import BookingInProgress from './components/BookingInProgress';
import Loader from './components/Loader';

export const BookingContext = createContext();

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ConditionalRoute = () => {
  const { currentUser, patientDetails, patientData } = useAuth();
  const location = useLocation();
  const query = useQuery();

  const [isLoading, setIsLoading] = React.useState(true);
  const [bookingInProgress, setBookingInProgress] = React.useState(false);
  const [bookingConfirmed, setBookingConfirmed] = React.useState(false);
  const [meetingType, setMeetingType] = React.useState(null);
  

  useEffect(() => {
    const checkBooking = async () => {
      try {
        const response = await axios.post('https://us-central1-joyous-web.cloudfunctions.net/v2/getOrSetIsCurrentlyBooking', {
          uid: currentUser.uid,
        });

        if (response.data.isCurrentlyBooking) {
          setBookingInProgress(true);

          // Remove booking progress
          setTimeout(() => {
            setBookingInProgress(false);
          }, 45000);

        } else {
          setBookingInProgress(false);
        }
      } catch (error) {
        console.error("Error fetching booking status:", error);
        setBookingInProgress(false); // Fail safe: assume no booking
      } finally {
        setIsLoading(false);
      }
    };

    if (currentUser?.uid) {
      console.log('checking!!!!!')
      // setIsLoading(false);
      // setBookingInProgress(true);
      checkBooking();
    } else {
      setIsLoading(false);
    }
  }, [currentUser]);

  const loadedAllData = currentUser && patientDetails && patientData;
  const isFollowup = location.pathname.includes('followup');
  const isOnDemand = location.pathname.includes('on-demand');

  // console.log('currentUser', currentUser);
  // console.log('patientDetails', patientDetails);
  // console.log('patientData', patientData);

  const isRescheduleOverride = getSessionStorageWithExpiry('rescheduling');

  const neverScheduled = patientDetails?.never_scheduled;
  const intakeComplete = patientData && (patientData?.intake_complete || patientData?.intake_complete === 'undefined');
  const hasMeetingScheduled = patientDetails?.meeting_in_patient_timezone_full_TZ;

  const searchParams = new URLSearchParams(location.search);

  // Proceed as normal no matter what
  if (isOnDemand) {
    <BookingContext.Provider value={{ bookingInProgress, setBookingInProgress, bookingConfirmed, setBookingConfirmed, meetingType, setMeetingType }}>
      <Outlet />
    </BookingContext.Provider>
  }

  if (isLoading) {
    return (
      <Loader
        className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${isLoading ? 'opacity-100' : 'opacity-0'}`}
      />
    );
  }

  if (bookingInProgress) {
    return (
    <BookingContext.Provider value={{ bookingInProgress, setBookingInProgress, bookingConfirmed, setBookingConfirmed, meetingType, setMeetingType }}>
      <BookingInProgress />;
    </BookingContext.Provider>
    );
  }

  if (isFollowup && patientData?.magic_id && (!query.get('dx') && !query.get('dr')) ) {
    window.location = 'https://joyous.team/followup/' + patientData.magic_id;
    return;
  }

  if (loadedAllData && !neverScheduled && !intakeComplete) {
    return <Navigate to={`/complete-intake?${searchParams.toString()}`} />;
  }


  if (hasMeetingScheduled && !isRescheduleOverride) {
    return <Navigate to={`/already-booked?${searchParams.toString()}`} />;
  }

  return (
    <BookingContext.Provider value={{ bookingInProgress, setBookingInProgress, bookingConfirmed, setBookingConfirmed, meetingType, setMeetingType }}>
      <Outlet />
    </BookingContext.Provider>
  );

  // return <Outlet/>;
};

export default ConditionalRoute;
