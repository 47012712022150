import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import _, { set } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStateFullName, getDrFromDxOrDr } from '../utils/utils';
import TimeslotsDaysContainer from './TimeslotsDaysContainer';
import TimezonePicker from './TimezonePicker';
import Loader from './Loader';
import { Video } from 'lucide-react';

const meetingTypeMap = {
  'new': 'New Patient Mental Wellbeing',
  'followup': 'Follow Up Mental Wellbeing',
  '500961': 'New Patient Mental Wellbeing',
  '487240': 'Follow Up Mental Wellbeing',
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TimeslotPicker() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isExiting, setIsExiting] = useState(false);
  const [isEntering, setIsEntering] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [seeProviderNowLoading, setSeeProviderNowLoading] = useState(false);
  const [providerNotAvailable, setProviderNotAvailable] = useState(false);

  useEffect(() => {
    setIsEntering(true);
  }, []);

  const query = useQuery();
  const [stateCode, setStateCode] = useState(query.get('state')?.toUpperCase());
  const [type, setType] = useState(query.get('type')); // new patient is default
  const [providerName, setProviderName] = useState('');
  const fromMeetNow = location.state?.fromMeetNow;
  const isFollowup = location.pathname.includes('followup');
  const isFollowUpByState = location.pathname.includes('follow-up-by-state');
  const isNewPatient = location.pathname.includes('book-appointment');

  const stateFullName = getStateFullName(stateCode);
  // const providerName = sessionStorage.getItem('drData')?.first_name;
  const meetingType = location.pathname.includes('followup') || location.pathname.includes('follow-up') ? 'Follow Up Mental Wellbeing' : 'New Patient Mental Wellbeing';
  const beginningOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

  const [timezone, setTimezone] = useState('');
  const [availableDates, setAvailableDates] = useState([]);
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [availableTimeslotsKeys, setAvailableTimeslotsKeys] = useState([]);
  const [drData, setDrData] = useState(null);
  const [dr, setDr] = useState(query.get('dr'));
  const [dx, setDx] = useState(query.get('dx'));

  const sanitizeAvailableDates = (dates) => {
    // Filter object where the values are true
    const availableDates = Object.keys(dates).filter(date => dates[date]);

    // Sort the dates in ascending order
    availableDates.sort((a, b) => new Date(a) - new Date(b));
    return availableDates;
  };

  // Fetch the dates that have available timeslots
  useEffect(() => {

    // if (!stateCode) {
    //   query.set('state', 'CA');
    //   setStateCode('CA');
    //   missingParams = true;
    // }

    // get dr
    // if (isFollowup && query.get('dx')) {
    //   getDr();
    // }


    // Force the type based on whether it is a followup or not in the url
    // if (!type) {
      if (isFollowup || isFollowUpByState) {
        query.set('type', '487240');
        setType('487240');
      } else {
        query.set('type', '500961');
        setType('500961');
      }
    // }


    if (!timezone) {
      console.log('no timezone')
      return;
    } else {
      console.log('timezone', timezone)
    }


    const fetchAvailableDates = async () => {
      setIsLoading(true);

      let drDataResult;

      if (isFollowup && (dx || dr)) {
        drDataResult = await getDrFromDxOrDr({dx, dr});

        if (!_.isEmpty(drDataResult)) {
          sessionStorage.setItem('drData', JSON.stringify(drDataResult));
          setDrData(drDataResult);
          setProviderName(drDataResult.first_name + ' ' + drDataResult.last_name);
        } else {
          navigate('/select-state', { replace: true });
          return;
        }
      } else if (isFollowup && !dx && !dr) {
        navigate('/select-state', { replace: true });
        return;
      }

      try {
        const useOnDemand = query.get('onDemand') === 'true';
        let endpoint = `https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableDates`;

        if (useOnDemand) {
          endpoint = `https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableOnDemandDates`;
        }

        const params = {
          state: stateCode,
          timezone,
          startDate: beginningOfMonth,
          endDate: moment(endOfMonth).add(1, 'month').format('YYYY-MM-DD'),
          dr: drDataResult?.dr || '',
          appointmentType: meetingType,
          redirect: 'https:/google.com/',
        };

        const response = await axios.get(endpoint, { params });
        if (response?.data.availableDates) {
          const sanitizedAvailableDates = sanitizeAvailableDates(response.data.availableDates);

          if (sanitizedAvailableDates.length > 0) {
            setAvailableDates(sanitizedAvailableDates);
          } else {
            console.log('No available dates');

            setIsLoading(false);
          }
        }

      } catch (error) {
        console.error('Error fetching available dates:', error);
      }

    };

    fetchAvailableDates();
  }, [stateCode, timezone]);

  // Fetch the timeslots for the selected date
  useEffect(() => {
    if (!stateCode && _.isEmpty(drData)) {
      return;
    }

    const fetchAvailableTimeslots = async () => {
      if (availableDates.length === 0) {
        return;
      }

      setIsLoading(true);

      try {
        const useOnDemand = query.get('onDemand') === 'true';

        let endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableTimeSlots';

        if (useOnDemand) {
          endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableOnDemandTimeSlots';
        }

        const params = {
          state: stateCode,
          timezone,
          startDate: availableDates[0],
          endDate: availableDates[availableDates.length - 1],
          dr: drData?.dr || '',
          appointmentType: meetingType,
          redirect: 'https:/google.com/'
        };

        const response = await axios.get(endpoint, { params });

        if (response?.data.slots) {
          const slots = response.data.slots;
          // Extend timeslots to have a field called 'type' and 'state
          _.each(slots, (timeslots, date) => {
            slots[date] = _.map(timeslots, (timeslot) => ({ ...timeslot,
              type,
              state: stateCode,
              timezone,
            }));
          });

          // -------------- ON DEMAND ----------------
          // If state is FL or NY
          if ((stateCode === 'WA' || stateCode === 'PA') && isNewPatient) {
            const onDemandDate = '2025-03-31';

            endpoint = 'https://us-central1-joyous-web.cloudfunctions.net/v2/getAvailableOnDemandTimeSlots';
            const params = {
              state: stateCode,
              timezone,
              startDate: onDemandDate,
              endDate: onDemandDate,
              dr: drData?.dr || '',
              appointmentType: meetingType,
              redirect: 'https:/google.com/'
            };

            const response = await axios.get(endpoint, { params });

            if (response?.data.slots) {
              const slotsForOnDemand = response.data.slots
              _.each(slotsForOnDemand, (timeslots, date) => {
                slotsForOnDemand[date] = _.map(timeslots, (timeslot) => ({ ...timeslot,
                  type,
                  state: stateCode,
                  timezone,
                  isOnDemand: true,
                }));
              });

              console.log('slotsForOnDemand', slotsForOnDemand);

              slots[onDemandDate] = slotsForOnDemand[onDemandDate];
            }


          }

          // Reorder the keys in slots since it is rendered on what was entered in the object first
          const orderedSlots = {};
          const slotKeys = Object.keys(slots).sort((a, b) => new Date(a) - new Date(b));
          slotKeys.forEach(key => {
            orderedSlots[key] = slots[key];
          });

          setAndShowTimeslots(orderedSlots);
        }

      } catch (error) {
        console.error('Error fetching available timeslots:', error);
      }
    };

    fetchAvailableTimeslots();
  }, [availableDates]);

  const setAndShowTimeslots = (timeslots) => {
    // Always show today and tomorrow
    // If you do not hit a total of 14 slots, keep showing days until you have a total of 14 days.
    // If it is after 4pm of your selected timezone, you do not count today so you show 2 days

    const today = moment().tz(timezone).format('YYYY-MM-DD');
    const isAfter4pm = moment().tz(timezone).isAfter(moment().tz(timezone).startOf('day').add(16, 'hours'));
    const daysToAlwaysShow = [
      today,
      moment().tz(timezone).add(1, 'day').format('YYYY-MM-DD'),
      isAfter4pm && moment().tz(timezone).add(2, 'day').format('YYYY-MM-DD')
    ].filter(Boolean);

    let numTimeslotsSoFar = 0;
    Object.keys(timeslots).forEach(date => {
      if (daysToAlwaysShow.includes(date) || numTimeslotsSoFar < 14) {
        if (timeslots[date]?.length) {
          numTimeslotsSoFar += timeslots[date].length;
        } else {
          delete timeslots[date];
        }
      } else {
        delete timeslots[date];
      }
    });

    setAvailableTimeslots(timeslots);
    setAvailableTimeslotsKeys(Object.keys(timeslots));

    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  };

  const handleSeeProviderNowClick = async (timeslot) => {
    if (seeProviderNowLoading) {
      return;
    }

    setSeeProviderNowLoading(true);

    sessionStorage.setItem('clicked-meet-now', true);

    try {
      const responderUuid = query.get('responder_uuid');

      if (responderUuid) {
        await axios.post('https://us-central1-joyous-web.cloudfunctions.net/v2/clickedMeetNow', {
          responderUuid,
        });
      }
    } catch (e) {
      console.error('Error clicking meet now:', e);
    }

    setTimeout(() => {
      console.log('is exiting??')
      setIsExiting(true);
      setTimeout(() => {
        console.log('no more exiting')
        setIsExiting(false);
        setProviderNotAvailable(true);
        setIsEntering(true);
      }, 1000);
    }, 2000);
  };


  const handleTimeslotClick = timeslot => {
    setIsExiting(true);
    setTimeout(() => {
      const searchParams = new URLSearchParams(location.search);
      let navigatePath = '';

      if (isFollowup) {
        navigatePath = `/followup/form?${searchParams.toString()}`;
      } else if (isFollowUpByState) {
        navigatePath = `/follow-up-by-state/form?${searchParams.toString()}`;
      } else {
        navigatePath = `/book-appointment/form?${searchParams.toString()}`;
      }

      const formData = location.state?.formData;
      console.log("clicked timeslot", timeslot);

      let isOnDemand = query.get('onDemand') === 'true' || timeslot.isOnDemand;

      navigate(navigatePath, {
        state: {
          timeslot,
          isEntering: true,
          onDemand: isOnDemand,
          ...formData && { formData },
        }
      });
    }, 500);
  };

  return (
    <div
      className={`min-h-screen flex flex-col font-sans fixed top-0 left-0 w-full h-full transition-opacity duration-500 ease-in-out ${
        isEntering && !isExiting ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      >
      <main className="flex-1 overflow-y-auto p-4">
        <div className="h-full p-6 max-w-md mx-auto bg-white rounded-lg text-center mt-0">
          <div className="space-y-4">
            <div className="mt-3">
              <p className="text-3xl font-extrabold">
                {!isFollowup ? `Joyous ${isFollowUpByState ? `Follow Up ${stateFullName}` : stateFullName || ''}` : providerName}
              </p>
              <p className="text-sm font-bold text-gray mt-2">{meetingType}</p>

              <p className="text-lg font-semibold text-gray text-center mt-4">Select Timezone</p>

              <TimezonePicker
                onChange={setTimezone}
              />

              {/* {isNewPatient &&
                !providerNotAvailable ? (
                <div className={`mt-0 pb-10 rounded-lg flex flex-col ${!providerNotAvailable ? 'opacity-100' : 'opacity-0'}`}>
                  <p className="text-2xl font-extrabold text-center">See a Provider Now</p>
                  <div className="text-center text-lg text-black pt-2 text-gray-600 font-bold">
                    <p>Check if a provider is available</p>
                  </div>
                  <button
                    className="w-50 h-14 text-lg shadow-xl bg-[#569df5] mt-5 text-white font-extrabold flex items-center justify-center rounded-md shadow-md"
                    onClick={e => handleSeeProviderNowClick()}
                  >
                    {seeProviderNowLoading ?
                    <Loader
                      height="h-8"
                      width="w-8"
                      borderTopColor="#569df5"
                    /> : <div>
                      <Video className="inline-block mr-2 mb-1" size={28}></Video> Meet Now
                      </div>
                    }
                  </button>
                </div>
                ) : (providerNotAvailable &&
                  <div className={`mt-0 p-4 rounded-lg flex bg-violet-100 flex-col mb-8 ${providerNotAvailable ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="text-center text-lg text-black text-gray-600 font-bold">
                    <p>Sorry, all providers are</p>
                    <p>currently busy.</p>
                    <p>Let's find a time that works for you.</p>
                  </div>
              </div>
                )
              } */}
            {/*
              {isNewPatient && (
                <div className={`mt-0 p-4 rounded-lg flex bg-violet-100 flex-col mb-8 ${providerNotAvailable ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="text-center text-lg text-black text-gray-600 font-bold">
                    <p>Sorry, all providers are</p>
                    <p>currently busy.</p>
                    <p>Let's find a time that works for you.</p>
                  </div>
              </div>
              )} */}

              {isLoading &&
                <Loader
                  className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000 ${isLoading ? 'opacity-100' : 'opacity-0'}`}
                />
              }

              {
                _.map(availableTimeslots, (timeslots, date) => (
                  <TimeslotsDaysContainer
                    key={date}
                    date={date}
                    timeslots={timeslots}
                    onClick={handleTimeslotClick}
                    className={`${date === availableTimeslotsKeys[availableTimeslotsKeys.length - 1] ? 'pb-40' : 'pb-8'} transition-opacity duration-500 ${!isLoading ? 'opacity-100' : 'opacity-0'}`}
                  />
                ))
              }
              {_.isEmpty(availableTimeslots) &&
                <div className={`transition-opacity duration-1000 ${!isLoading ? 'opacity-100' : 'opacity-0'}`}>
                  <div className="mt-20 text-2xl w-30 font-semibold text-gray text-center mt-4">
                    <p>
                      Sorry, there are no appointments available at this time.
                    </p>
                    <p>Please try again tomorrow.</p>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default TimeslotPicker;
