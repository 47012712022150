
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { db } from '../utils/firebase';
import { doc, updateDoc, arrayUnion, arrayRemove, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from './Loader';

import {
  ControlBar,
  GridLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  VideoConference,
  useLocalParticipant,
} from '@livekit/components-react';

import '@livekit/components-styles';
import { Track } from 'livekit-client';
import MyVideoConference from './MyVideoConference';

const SERVER_URL = 'wss://joyous-4qypx3an.livekit.cloud';

const PatientApp = () => {
  const navigate = useNavigate();
  const { currentUser, patientDetails, patientData, isLoading } = useAuth();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || 'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const [patientName, setPatientName] = useState(patientData?.firstName + ' ' + patientData?.lastName);
  const [nurseOrDoctorJoined, setNurseOrDoctorJoined] = useState(false);
  const nurseOrDoctorJoinedRef = useRef(false);
  const [nurseOrDoctorJoinedOnce, setNurseOrDoctorJoinedOnce] = useState(false);
  const [joinedWaitingRoom, setJoinedWaitingRoom] = useState(!document.hidden ? true : false);
  const [token, setToken] = useState(null);
  const [callFinished, setCallFinished] = useState(false);
  const videoRef = useRef(null);
  const inactivityTimerRef = useRef(null);

  // Make leave call button have a click event
  // useEffect(() => {
  //   // Ask for user mic and video
  //   navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user"}});

  //   const observer = new MutationObserver((mutations) => {
  //     const leaveCallButton = document.querySelector('.lk-disconnect-button');

  //     leaveCallButton?.addEventListener('click', () => {
  //       console.log('call finished')
  //       setCallFinished(true);
  //     });
  //   });

  //   observer.observe(document.body, { childList: true, subtree: true });

  //   stopLocalTracks();

  //   return () => observer.disconnect();
  // }, []);

  // Stop portrait mode
  useEffect(() => {
    const handleResize = () => {
      // setIsMobile(window.innerWidth <= 768);
      setIsLandscape(window.innerWidth > window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {

    const handleTimer = () => {

      // if document is visible, clear the timer
      if (!document.hidden ) {
        if (inactivityTimerRef.current) {
          clearTimeout(inactivityTimerRef.current);
          inactivityTimerRef.current = null;
        }
        return;
      }

      const timer = () => {
        inactivityTimerRef.current = setTimeout(() => {
          if (nurseOrDoctorJoinedRef.current) {
            timer();
          } else {
            // close call
            onDisconnected();
          }
        }, 1000 * 60 * 3); // 3 minutes
      }

      timer();
  };

  document.addEventListener("visibilitychange", handleTimer);

  return () => {
    document.removeEventListener("visibilitychange", handleTimer);
  };

  }, []);

  // Stop pinch zoom
  useEffect(() => {
    // Create a meta tag to disable zoom
    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no';
    document.head.appendChild(metaTag);

    // Clean up: remove the meta tag when the component unmounts
    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  // Redirect if not authenticated
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!currentUser?.uid && !isLoading) {
      navigate(`/login`, {
        state: {
          isOnDemand: true,
        }
      });
    }

  }, [isLoading, currentUser]);

  const handleVideoClick = () => {
    console.log('hello????')
    console.log(videoRef.current)
    if (videoRef.current) {
      console.log('is it paused', videoRef.current.paused)
      // videoRef.current.play();
      if (videoRef.current.paused) {
        console.log("playing")
        videoRef.current.play();
      } else {
        console.log('pausing')
        videoRef.current.pause();
      }
    }
  };

  const createRoom = async (uid) => {
    if (!uid) {
      return;
    }

    try {
      const result = await axios.post('https://us-central1-joyous-web.cloudfunctions.net/onDemandAssignPatientToRoom', {
      // const result = await axios.post('http://localhost:5001/joyous-web/us-central1/onDemandAssignPatientToRoom', {
        uid: currentUser.uid,
      });

      // setToken(result.data.token);

    } catch (e) {
      console.error('Error generating token:', e);
    }
  };

  useEffect(() => {
    if (token) {
      return;
    }

    if (!joinedWaitingRoom) {
      return;
    }

    const uid = currentUser?.uid;

    if (!uid) {
      return;
    }

    // listener for token
    const tokenRef = doc(db, 'customers', uid);
    const unsubscribe = onSnapshot(tokenRef, (doc) => {
      const data = doc.data();
      console.log('setting the token')
      if (data?.on_demand?.token) {
        setToken(data.on_demand.token);
      } else {
        setToken(null);
      }
    });

  //   console.log('creating room')
    createRoom(uid);
    // return;
    return () => unsubscribe();
  }, [currentUser, joinedWaitingRoom]);

  useEffect(() => {
    if (!nurseOrDoctorJoined) {
      return;
    }

    if (nurseOrDoctorJoinedOnce) {
      return;
    }

    if (nurseOrDoctorJoined && !nurseOrDoctorJoinedOnce) {
      setNurseOrDoctorJoinedOnce(true);
    }

  }, [nurseOrDoctorJoined]);

  const stopLocalTracks = () => {
    console.log('stopping local tracks')
    const videoTracks = document.querySelectorAll("video");
    videoTracks.forEach((video) => (video.srcObject = null)); // Ensures media stream is released

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch((err) => console.error("Error stopping media tracks:", err));
  };

  const onDisconnected = () => {
    // console.log('on disconnect')
    setCallFinished(true);
    stopLocalTracks();
    // setTimeout(() => {
    //   setCallFinished(true);
    // }, 100);
  };

  const onConnected = () => {
    console.log('CONNCTED!!!!')

    setTimeout(async() => {
      const devices =  await navigator.mediaDevices.enumerateDevices();
      console.log('devices', devices)
      const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
      const defaultAudioDevice = audioInputDevices.length > 0 ? audioInputDevices[0] : null;
      console.log(`Default audio input device: ${defaultAudioDevice}`);

    }, 500);
  }

  const onJoin = async () => {
    setJoinedWaitingRoom(true);
  };

  if (isLoading) {
    console.log('loading')
    return (
      <Loader
        className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000`}
      />
    );
  }

  if (!currentUser?.uid && !setCallFinished) {
    console.log('no uid')
    // return <div>Loading...</div>;
    return (
      <Loader
      className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000`}
    />
    )
  }

  if (!token && !setCallFinished) {
    console.log('no token')
    // return <div>Loading...</div>;
    return (
      <Loader
        className={`absolute inset-0 flex items-center justify-center transition-opacity duration-1000`}
      />
    )
  }

  if (callFinished) {
    return <div className="flex items-center justify-center min-h-screen bg-[#f6f0ff]">
    <div className="bg-white shadow-md rounded-lg p-10 text-center">
      <h1 className="text-4xl font-bold text-[#a988e0] mb-6">Thank You!</h1>
      <p className="text-lg text-gray-700 mb-8">
        We appreciate you taking the time to meet with us today. We hope you had a great experience.
      </p>
      <p className="text-lg text-gray-700">
        If exiting the call was a mistake, please refresh the page.
      </p>
      <div className="flex justify-center">
        {/* <button className="bg-[#a988e0] text-white font-semibold py-2 px-6 rounded-full shadow hover:bg-[#8d6ec9] transition duration-300">
          Close
        </button> */}
      </div>
    </div>
  </div>
  }

  if (!joinedWaitingRoom) {
    return <div className="flex items-center justify-center min-h-screen bg-[#f6f0ff]">
      <div className="bg-white shadow-md rounded-lg p-10 text-center">
        <h1 className="text-4xl font-bold text-[#a988e0] mb-6">Joyous Meeting</h1>
        <p className="text-lg text-gray-700 mb-8">
          You will need to wait while we prepare the meeting with a Joyous provider.
        </p>
        <div className="flex justify-center">
          <button
            onClick={onJoin}
            className="bg-[#a988e0] text-white font-semibold py-2 px-6 rounded-full shadow hover:bg-[#8d6ec9] transition duration-300"
          >
            Join Waiting Room
          </button>
        </div>
      </div>
    </div>
  }

  // if (!nurseOrDoctorJoined) {

    return (
      <div
        className="h-full"
        // className="h-screen flex flex-col"
      >
        {isMobile && isLandscape && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-sm text-white text-center text-lg px-6 z-50">
            Please rotate your device to portrait mode for the best experience.
          </div>
        )}

        {!isMobile && (
          <div className={`flex flex-col items-center justify-center h-1/4 bg-gray-100 p-2 md:p-4 relative ${nurseOrDoctorJoined && 'hidden'}`}>
            <h1 className="text-lg md:text-xl font-semibold text-center px-2">New Patient Meeting Room</h1>
            <p className="text-gray-600 mt-1 md:mt-2 text-sm md:text-base text-center">Waiting for a Joyous Nurse or Provider to join the meeting.</p>
            <div className="flex mt-1 md:mt-2 space-x-2 md:space-x-4">
            </div>
          </div>
        )}

        {/* Bottom half for the responsive video */}
        <div
          className={`${nurseOrDoctorJoined ? 'h-full' : 'h-3/4'} bg-gray-200 flex items-center justify-center p-2 md:p-4`}
        >
        <main
      style={{
        height: '100%',
        // position: 'relative',
        // display: 'grid',
        // gap: '1rem',
        // 'justifyContent': 'center',
        // 'placeContent': 'center',
        // 'justifyitems': 'center',
        // overflow: 'auto',
        // 'flexGrow': '1',
      }}
      className="w-full h-full"
      // style={{ height: '100vh', width: '100vw' }}
      >
      <LiveKitRoom
        video={true}
        audio={true}
        token={token}
        serverUrl={SERVER_URL}
        // Use the default LiveKit theme for nice styles.
        data-lk-theme="default"
        // style={{ height: '100vh' }}
        // style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
        onDisconnected={() => { onDisconnected() }}
        onConnected={() => { onConnected() }}
        onMediaDeviceFailure={(e) => { console.log('media device failure', e) }}
        onError={(e) => { console.log('error', e) }}
      >
        <div
        // className="flex flex-col h-screen overflow-hidden"
        className="h-full"
        >
          {/* Video Area */}
          {/* <div className="relative flex-1"> */}
          <div
          // className="w-full h-full"
          // className="flex-1 flex items-center justify-center relative bg-black"
          className="h-full"
          >
            <MyVideoConference
              setNurseOrDoctorJoined={setNurseOrDoctorJoined}
              nurseOrDoctorJoinedRef={nurseOrDoctorJoinedRef}
              nurseOrDoctorJoinedOnce={nurseOrDoctorJoinedOnce}
            />
            {/* <VideoConference/> */}
          </div>

          <RoomAudioRenderer />

          {/* Control Bar */}
          {/* <div className="absolute bottom-0 left-0 w-full bg-black"> */}
            {/* <ControlBar/> */}
          {/* </div> */}
        </div>

      </LiveKitRoom>
      </main>

      </div>
      {isMobile && (
      <div className={`flex flex-col items-center justify-center h-1/4 bg-gray-100 p-2 md:p-4 relative ${nurseOrDoctorJoined && 'hidden'}`}>
        <h1 className="text-lg md:text-xl font-semibold text-center px-2">New Patient Meeting Room</h1>
        <p className="text-gray-600 mt-1 md:mt-2 text-sm md:text-base text-center">Waiting for a Joyous Nurse or Provider to join the meeting.</p>
        <div className="flex mt-1 md:mt-2 space-x-2 md:space-x-4">
        </div>
      </div>
      )}


      </div>
    );
  // }

  return (
    <main
    // data-lk-theme="default"
    style={{
      height: '100%',
      // position: 'relative',
      // display: 'grid',
      // gap: '1rem',
      // 'justifyContent': 'center',
      // 'placeContent': 'center',
      // 'justifyitems': 'center',
      // overflow: 'auto',
      // 'flexGrow': '1',
     }}
    // className="w-full h-full"
    // style={{ height: '100vh', width: '100vw' }}
    >
    <LiveKitRoom
      video={true}
      audio={true}
      token={token}
      serverUrl={SERVER_URL}
      // Use the default LiveKit theme for nice styles.
      data-lk-theme="default"
      // style={{ height: '100vh' }}
      // style={{ height: 'calc(var(--vh, 1vh) * 100)' }}
      onDisconnected={() => { onDisconnected() }}
    >
      <div
      // className="flex flex-col h-screen overflow-hidden"
      className="h-full"
      >
        {/* Video Area */}
        {/* <div className="relative flex-1"> */}
        <div
        // className="w-full h-full"
        // className="flex-1 flex items-center justify-center relative bg-black"
        className="h-full"
        >
          <MyVideoConference
            setNurseOrDoctorJoined={setNurseOrDoctorJoined}
          />
          {/* <VideoConference/> */}
        </div>

        <RoomAudioRenderer />

        {/* Control Bar */}
        {/* <div className="absolute bottom-0 left-0 w-full bg-black"> */}
          {/* <ControlBar/> */}
        {/* </div> */}
      </div>

    </LiveKitRoom>
    </main>
  );



  return (
    <div className="flex flex-col min-h-screen w-full">
      <div className="relative flex-grow w-full" style={{flexBasis: '90%'}}>
      {/* <h1>Welcome, Patient {patientName}</h1> */}
      <LiveKitRoom
        video={true}
        audio={true}
        token={token}
        serverUrl={SERVER_URL}
        // Use the default LiveKit theme for nice styles.
        data-lk-theme="default"
        style={{ height: '100vh' }}
        onDisconnected={() => { onDisconnected() }}
      >
        {/* Your custom component with basic video conferencing functionality. */}
        {/* <VideoConference/> */}
        <MyVideoConference />
        {/* The RoomAudioRenderer takes care of room-wide audio for you. */}
        <RoomAudioRenderer />
        {/* Controls for the user to start/stop audio, video, and screen
        share tracks and to leave the room. */}
         <div className="w-full" style={{flexBasis: '10%'}}>
          <ControlBar />
        </div>
      </LiveKitRoom>
      </div>
    </div>
  );
};

export default PatientApp;
