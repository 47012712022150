import _, { set } from 'lodash';

import React, { useState, useEffect, useRef } from 'react';

import {
  ControlBar,
  GridLayout,
  FocusLayout,
  FocusLayoutContainer,
  CarouselLayout,
  LiveKitRoom,
  ParticipantTile,
  RoomAudioRenderer,
  useTracks,
  useParticipants,
  useLocalParticipant,
  useTrackRefContext,
  VideoTrack,
  VideoConference,
  useRoomContext,
  MediaDeviceSelect,
  MediaDeviceMenu,
  TrackToggle,
} from '@livekit/components-react';

import { Play, Pause, Volume2, VolumeX } from 'lucide-react';
import '@livekit/components-styles';

import { Track } from 'livekit-client';

function MyVideoConference({ setNurseOrDoctorJoined, nurseOrDoctorJoinedOnce, nurseOrDoctorJoinedRef }) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(true);
  const [showControls, setShowControls] = useState(isMobile ? true : false);
  const [showControlsTimeout, setShowControlsTimeout] = useState(null);
  const [AudioDeviceX, setAudioDeviceX] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);

  const room = useRoomContext();

  const handleVideoClick = () => {
    console.log('videRef', videoRef.current)
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  };


    // Update play/pause state on load
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const handlePlay = () => {
      setIsPlaying(true)
    };
    const handlePause = () => {
      setIsPlaying(false);
    };

    videoRef.current.addEventListener("play", handlePlay);
    videoRef.current.addEventListener("pause", handlePause);

    return () => {
      videoRef.current.removeEventListener("play", handlePlay);
      videoRef.current.removeEventListener("pause", handlePause);
    };
  }, []);

  const togglePlayPause = (e) => {
    e.stopPropagation();

    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleMuteToggle = (e) => {
    e.stopPropagation();

    const isMuted = videoRef.current.muted;
    videoRef.current.muted = !isMuted;
    setMuted(!isMuted);
  };

  // do nothing for now
  const toggleControls = () => {
    return;
    if (!isMobile) return;


    setShowControls(!showControls);
  };

  // const localParticipant = useLocalParticipant();
  // Get all participants in the room
  // const participants = useParticipants();
  // Filter out local participant from the list of participants
  // const remoteParticipants = participants.filter(p => !p.isLocal);

  // `useTracks` returns all camera and screen share tracks. If a user
  // joins without a published camera track, a placeholder track is returned.
  const tracks = useTracks(
    [
      { source: Track.Source.Camera, withPlaceholder: false },
      { source: Track.Source.ScreenShare, withPlaceholder: false },
    ],
    { onlySubscribed: false },
  );
  // const tracks = useTracks([Track.Source.Camera]);

  // console.log('tracks', tracks)
  const allTracks = tracks;

  if (AudioDeviceX)
  setTimeout(async() => {
    const devices =  await navigator.mediaDevices.enumerateDevices();
    console.log('devices', devices)
    const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
    const defaultAudioDevice = audioInputDevices.length > 0 ? audioInputDevices[0] : null;
    console.log("Default audio input device",defaultAudioDevice);

    if (defaultAudioDevice == null || defaultAudioDevice.deviceId == null || defaultAudioDevice.deviceId === '') return;
      try {
      // Switch to the default audio input device
      await room.switchActiveDevice('audioinput', defaultAudioDevice.deviceId);
      console.log(`Switched to default audio device: ${defaultAudioDevice.label}`);
    } catch (error) {
      console.error('Failed to switch audio device:', error);
    }
    setAudioDeviceX(false);
  }, 500);

  const remoteTracks = tracks.filter((track) => track.participant.identity.includes('nurse')
    || track.participant.identity.includes('doctor')
    || track.participant.identity.includes('agent'));
  const localTrack = tracks.filter((track) => track.participant.identity.includes('patient'))[0];


  let tracksToUse = null;

  if (remoteTracks.length) {
    tracksToUse = remoteTracks;
  } else if (localTrack) {
    tracksToUse = [localTrack];
  }

  if (remoteTracks.length > 1) {
    setNurseOrDoctorJoined(true);
    nurseOrDoctorJoinedRef.current = true;
  }

  useEffect(() => {
    if (remoteTracks.length > 0) {
      setNurseOrDoctorJoined(true);
      nurseOrDoctorJoinedRef.current = true;
    } else {
      setNurseOrDoctorJoined(false);
      nurseOrDoctorJoinedRef.current = false;
    }
  }, [remoteTracks]);

  if (_.isEmpty(tracksToUse)) {
  // if (true) {
    // return <div>Loading...</div>;
    return (
      <div
        // className="flex items-center justify-center font-bold text-2xl"
        // className="flex items-center justify-center h-screen font-bold text-2xl"
        className="flex items-center justify-center h-screen w-full text-center font-bold text-2xl px-4"
      >
        Please Enable Camera and Microphone to Continue
      </div>
    )
  }


   return (
      <div
        className="lk-video-conference"
      >
        <div
          className="lk-video-conference-inner"
        >
          <div
            className="lk-grid-layout-wrapper"
            // style={{height: "calc(100% - var(--lk-control-bar-height))"}}
          >
            {remoteTracks.length > 0 && (
              <GridLayout tracks={tracksToUse}>
                <ParticipantTile />
              </GridLayout>
            )}

            {remoteTracks.length === 0 && (
            <div
              className="w-full h-full object-contain"
              onMouseEnter={() => setShowControls(true)}
              onMouseLeave={() => setShowControls(false)}
              onClick={toggleControls} // For mobile tap
            >
              <video
                ref={videoRef}
                className="w-full h-full object-contain"
                // autoPlay={!nurseOrDoctorJoinedOnce}
                autoPlay={true}
                muted={true}
                playsInline
                disablePictureInPicture
                src="https://joyous-s3.s3.us-west-1.amazonaws.com/cdn/videos/welcome_video.mp4"
                type="video/mp4"
              />
              <div
                // className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4"
                className={`absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4 transition-opacity duration-300 ${
                  showControls ? "opacity-100" : "opacity-0"
                }`}
              >
                 <button
                  onClick={e => togglePlayPause(e)}
                  className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 focus:outline-none"
                >
                  {isPlaying ? <Pause className="w-5 h-5" /> : <Play className="w-5 h-5" />}
                </button>
                <button
                  onClick={e => handleMuteToggle(e)}
                  className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 focus:outline-none"
                >
                  {muted ? (
                    <VolumeX className="w-5 h-5" />
                  ) : (
                    <Volume2 className="w-5 h-5" />
                  )}
                </button>
              </div>
          </div>
            )}
          </div>
          <ControlBar />
        </div>
        {/* <MediaDeviceSelect kind="audioinput" /> */}
        {/* <MediaDeviceMenu /> */}

        {localTrack && (
          <div className={`absolute top-2 left-2 ${remoteTracks.length > 0 ? '' : 'hidden'}`}>
          {/* // <div className="absolute bottom-0 right-4 w-64 h-64"> */}
            <ParticipantTile
            trackRef={localTrack}
              // isLocal={true}
              // width="100%"
              // height="100%"
              className="h-36 w-32"
            />
          </div>
        )}

      </div>
  );

  return (
    <div
      // className="flex justify-center items-center h-full w-full bg-black"
      >
          <div
          // className="relative w-full h-full max-w-full max-h-full"
          >
      <GridLayout tracks={allTracks}>
      <div
      // className="aspect-w-16 aspect-h-9 object-contain w-full h-full"
      >
        <ParticipantTile
          // className="object-contain w-full h-full"
        />
        </div>
      </GridLayout>
      </div>
    </div>
  );


  return (
  <div
  // className="max-h-80"
  // className="w-full h-full flex items-center justify-center"
  // className="relative w-full h-full"
  // className="flex justify-center items-center w-full"
  // className="flex justify-center items-center h-screen w-full overflow-hidden"
    // className="w-full h-full flex items-center justify-center bg-black overflow-hidden"
  >
  <div
  // className="grid grid-cols-1 md:grid-cols-2 gap-4"
    // className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full h-full"
    // className="w-full h-full flex portrait:flex-col landscape:flex-row"
    // className="w-full h-full flex flex-row"
  >
  {/* Remote participant full screen */}
    {remoteTracks.length && remoteTracks.map(remoteTrack => (
      // {/*// <div className="absolute inset-0 w-full h-full">*/}
      <div
      // className="flex items-center justify-center w-full h-full bg-black"
      // className="aspect-w-16 aspect-h-9 w-full h-full"
      //  className="relative w-full aspect-w-16 aspect-h-9"
        // className="relative w-full h-full flex justify-center items-center"
        // className="w-full h-full flex justify-center items-center"
        // className="flex-1 flex items-center justify-center bg-black overflow-hidden"
      >
        <div
          // className="w-full h-auto max-w-full max-h-full"
          // className="w-full h-auto aspect-video"
          //  className="w-full h-auto aspect-video"
        >
        <ParticipantTile
          key={remoteTrack.participant.identity}
          trackRef={remoteTrack}
          // isLocal={false}
          // className="w-full h-full object-cover"
          // className="w-full h-full object-contain"
          //  className="w-full h-full object-contain-video"
          //  className="absolute inset-0 w-full h-full object-contain"
            // className="w-full h-auto object-contain"
          // className="obtain-contain"
          // width="100%"
          // height="100%"
        />
        </div>

        </div>
      )
    )}
  </div>

  {/* Local video (You) in bottom-right corner */}
  {
  // localTrack && (
  //   <div className="absolute bottom-8 right-4 w-48 h-36">
  //   {/* // <div className="absolute bottom-0 right-4 w-64 h-64"> */}
  //     <ParticipantTile
  //     trackRef={localTrack}
  //       // isLocal={true}
  //       width="100%"
  //       height="100%"
  //     />
  //   </div>
  // )
  }
</div>
  );

  // return (
  //   <div>
  //     {localTrack ? <ParticipantTile trackRef={localTrack} />
  //       : <div>Loading...</div>
  //     }
  //   </div>
  // );


  // return (
  //   // <GridLayout tracks={tracks} style={{ height: 'calc(100vh - var(--lk-control-bar-height))' }}>
  //   <VideoConference tracks={tracks}>
  //     {/* The GridLayout accepts zero or one child. The child is used
  //     as a template to render all passed in tracks. */}
  //     <ParticipantTile />
  //   </VideoConference>
  //   // </GridLayout>
  // );
}

export default MyVideoConference;
