import React, { useEffect, useContext, useState } from 'react';
import { auth, db } from '../utils/firebase';
import { useAuth } from '../AuthContext';
import moment from 'moment';
import { BookingContext } from '../ConditionalRoute';
import { query, collection, where, orderBy, doc, getDoc, addDoc, setDoc, onSnapshot } from 'firebase/firestore';
import { getSessionStorageWithExpiry } from '../utils/utils';
import axios from 'axios';

const BookingInProgress = () => {
  const { currentUser } = useAuth();
  const uid = currentUser?.uid;
  const { setBookingInProgress, setBookingConfirmed, setMeetingType, meetingType } = useContext(BookingContext);

    const [messageIndex, setMessageIndex] = useState(0);

    const messages = [
      'Booking your appointment...',
      'Confirming availability...',
      'Securing your time slot...',
      'Finalizing your booking...',
      'Almost done...',
      'Thank you for your patience!',
    ];

    useEffect(() => {
      const interval = setInterval(() => {
        setMessageIndex(prevIndex => {
          if (prevIndex < messages.length - 1) {
            return prevIndex + 1;
          } else {
            clearInterval(interval);
            return prevIndex;
          }
        });
      }, 3000); // Change message every 3 seconds

      return () => clearInterval(interval);
    }, []);

  useEffect(() => {
    if (!uid) return;

    const customerDocRef = doc(db, 'customers', uid);
    const meetingsSubcollectionRef = collection(customerDocRef, 'meetings');

    const q = query(
      meetingsSubcollectionRef,
      where('meeting_in_doctor_timezone_ts', '>', moment().unix() * 1000),
      orderBy('meeting_in_doctor_timezone_ts', 'desc')
    );

    // Set up the real-time listener
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const futureMeetings = [];
      const pastMeetingId = getSessionStorageWithExpiry('rescheduling');

      console.log('this is the past meeting id', pastMeetingId)

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const status = data.status;
        const meetingTimeTs = data.meeting_in_doctor_timezone_ts;
        const isFuture = moment(meetingTimeTs).isAfter(moment());
        const appointmentId = data.id;
        const appointmentIdMatchOldAppointment = appointmentId === pastMeetingId;



        // Meetings created with empty status first
        if (['Confirmed', 'Not Confirmed', ''].includes(status) && isFuture && !appointmentIdMatchOldAppointment) {
          futureMeetings.push({
            id: doc.id,
            ...data,
          });
        }
      });

      console.log('this is the future meetings!!!!!!!!!!!', futureMeetings)

      if (futureMeetings.length) {
        console.log(futureMeetings)
        await removeBookingInProgress();
        setMeetingType(await calcMeetingType(futureMeetings[0].id))
        console.log("meetingType!!!",meetingType);
        setBookingInProgress(false);
        setBookingConfirmed(true);
       
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [uid]);

  const removeBookingInProgress = async () => {
    if (!currentUser?.uid) {
      return;
    }

    const response = await axios.post('https://us-central1-joyous-web.cloudfunctions.net/v2/getOrSetIsCurrentlyBooking', {
      uid: currentUser.uid,
      start: false,
    });
  };


  const calcMeetingType = async (meeting_id) => {
    if (!currentUser?.uid) {
      return null;
    }

    const response = await axios.post('https://n8n.joyous.team/webhook/calc-meeting-type', {
      uid: currentUser.uid,
      meeting_id: meeting_id,
      code: "34f56ce4-1e0b-4807-8da6-5b6188e8b2c8"
    }).catch((e) => {
      console.error('Error calculating meeting type:', e);
    });

    if (response?.data?.meeting_type) {
      console.log('meetingType', response.data.meeting_type);
      return response.data.meeting_type;
    }

  };



  return (
    <div className="font-sans fixed inset-0 flex items-center justify-center bg-gradient-to-b from-[#a988e0] to-[#5c4492]">
      <div className="text-center text-white px-4">
        <svg
          className="animate-spin h-12 w-12 md:h-16 md:w-16 mb-6 mx-auto text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
          ></path>
        </svg>
        <h1
          className="text-2xl md:text-4xl font-bold mb-4"
          aria-live="polite"
        >
          {messages[messageIndex]}
        </h1>
        <p className="text-base md:text-lg">
          Please wait while we process your request.
        </p>
      </div>
    </div>
  );
};

export default BookingInProgress;
